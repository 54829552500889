import React from "react";
import dateFormat from 'dateformat';
import {baseURL} from "../../api/axiosInstance";
import { Link } from "react-router-dom";

export default class PostSection extends React.Component {
  render() {
    let { post } = this.props;
    return (
      <React.Fragment>
        <div className="post post-classic rounded bordered">
          {/* <!-- thumbnail --> */}
          <div className="thumb rounded">
            <a
              href="#"
              className="category-badge lg position-absolute"
            >
              {post.category.name}
            </a>
            <span className="post-format">
              <i className="icon-picture"></i>
            </span>
            <Link to={`/how-to/${post.slug}`}>
              <div className="inner">
                <img src={`${baseURL}${post.image}`} alt={post.title} />
              </div>
            </Link>
          </div>
          {/* <!-- details --> */}
          <div className="details">
            {/* <!-- title section --> */}
            {/* <div className="post-top"> */}
              <ul className="meta list-inline mb-0">
                <li className="list-inline-item">
                  <a href="#">
                    {/* <img
                      src="images/other/author-sm.png"
                      className="author"
                      alt="author"
                    /> */}
                    {post.author.username}
                  </a>
                </li>
                <li className="list-inline-item">{dateFormat(post.created_on, "mmmm dS, yyyy")}</li>
                <li className="list-inline-item">
                  <i className="icon-bubble"></i> (0)
                </li>
              </ul>
              <h5 className="post-title mb-0 mt-4">
                <Link to={`/how-to/${post.slug}`}>{post.title}</Link>
              </h5>
              <p className="excerpt mb-0">
                {post.metades}
              </p>
            {/* </div> */}
          </div>
          <div className="post-bottom clearfix d-flex align-items-center">
            <div className="social-share me-auto">
              <button className="toggle-button icon-share"></button>
              <ul className="icons list-unstyled list-inline mb-0">
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-pinterest"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="far fa-envelope"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="float-end d-none d-md-block">
              <Link to={`/how-to/${post.slug}`} className="more-link">Continue reading<i className="icon-arrow-right"></i></Link>
            </div>
            <div className="more-button d-block d-md-none float-end">
              <Link to={`/how-to/${post.slug}`}>
                <span className="icon-options"></span>
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
