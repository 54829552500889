import React, { Component } from 'react'
import waveSvg from "../../assets/images/wave.svg";
import axiosInstance, { baseURL } from "../api/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class ContactUs extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    try {
      let res = await axiosInstance.post(`/contact`, {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
      });
      toast.success(res.data.message, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        theme: "colored",
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      this.setState({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (err) {
      if (err.response.data.message != undefined){
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    }
  }
  
  render() {
    return (
      <React.Fragment>
      <section className="page-header">
        <div className="container-xl">
          <div className="text-center">
            <h1 className="mt-0 mb-2">Contact</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center mb-0">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      {/* <!-- section main content --> */}
      <section className="main-content">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-4">
              {/* <!-- contact info item --> */}
              <div className="contact-item bordered rounded d-flex align-items-center">
                <span className="icon icon-envelope-open"></span>
                <div className="details">
                  <h3 className="mb-0 mt-0">E-Mail</h3>
                  <p className="mb-0">info@tekpython.com</p>
                </div>
              </div>
              <div
                className="spacer d-md-none d-lg-none"
                data-height="30"
              ></div>
            </div>

            <div className="col-md-4">
              {/* <!-- contact info item --> */}
              <div className="contact-item bordered rounded d-flex align-items-center">
                <span className="icon icon-phone"></span>
                <div className="details">
                  <h3 className="mb-0 mt-0">Facebook</h3>
                  <p className="mb-0">facebook.com/review2tech</p>
                </div>
              </div>
              <div
                className="spacer d-md-none d-lg-none"
                data-height="30"
              ></div>
            </div>

            <div className="col-md-4">
              {/* <!-- contact info item --> */}
              <div className="contact-item bordered rounded d-flex align-items-center">
                <span className="icon icon-map"></span>
                <div className="details">
                  <h3 className="mb-0 mt-0">Telegram</h3>
                  <p className="mb-0">t.me/review2tech</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5"></div>

          {/* <!-- section header --> */}
          <div className="section-header">
            <h3 className="section-title">Send Message</h3>
            <img src={waveSvg} className="wave" alt="wave" />
          </div>

          {/* <!-- Contact Form --> */}
          <form id="contact-form" className="contact-form" method="post" onSubmit={this.handleSubmit}>
            <div className="messages"></div>

            <div className="row">
              <div className="column col-md-6">
                {/* <!-- Name input --> */}
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="InputName"
                    placeholder="Your name"
                    required="required"
                    data-error="Name is required."
                    value={this.state.name}
                    onChange={this.handleInputChange}
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>

              <div className="column col-md-6">
                {/* <!-- Email input --> */}
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    id="InputEmail"
                    name="email"
                    placeholder="Email address"
                    required="required"
                    data-error="Email is required."
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>

              <div className="column col-md-12">
                {/* <!-- Email input --> */}
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="InputSubject"
                    name="subject"
                    placeholder="Subject"
                    required="required"
                    data-error="Subject is required."
                    value={this.state.subject}
                    onChange={this.handleInputChange}
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>

              <div className="column col-md-12">
                {/* <!-- Message textarea --> */}
                <div className="form-group">
                  <textarea
                    name="message"
                    id="InputMessage"
                    className="form-control"
                    rows="4"
                    placeholder="Your message here..."
                    required="required"
                    data-error="Message is required."
                    value={this.state.message}
                    onChange={this.handleInputChange}
                  ></textarea>
                  <div className="help-block with-errors"></div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              name="submit"
              id="submit"
              value="Submit"
              className="btn btn-default"
            >
              Submit Message
            </button>
          </form>
          {/* <!-- Contact Form end --> */}
        </div>
      </section>
      <ToastContainer />
    </React.Fragment>
    )
  }
}

