import React, { Component } from "react";
import PostSection from "./components/Post_section";
import RightSidebar from "../tutorials/Right_sidebar";
import axiosInstance from "../api/axiosInstance";
import $ from "jquery";

export default class Tutorials extends Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      count: 0,
      current_page: 0,
      nextPage: "",
      prevPage: "",
    };
    this.paginateNextBlogs = this.paginateNextBlogs.bind(this);
    this.paginatePrevBlogs = this.paginatePrevBlogs.bind(this);
  }

  async componentDidMount() {
    let res = await axiosInstance.get(`/tutorials/`);
    let data = await res.data;
    this.setState({
      posts: data.results,
      count: data.count,
      current_page: data.current_page,
      nextPage: data.next,
      prevPage: data.previous,
    });

    var $headerClone = $(
        ".header-default.clone, .header-personal nav.clone, .header-classic.clone .header-bottom.clone"
      ),
      $cloneHeader = $headerClone.remove();

    var $header = $(
        ".header-default, .header-personal nav, .header-classic .header-bottom"
      ),
      $clone = $header.before($header.clone().addClass("clone"));

    $(window).on("scroll", function () {
      var fromTop = $(window).scrollTop();
      $("body").toggleClass("down", fromTop > 300);
    });
  }

  async paginateNextBlogs() {
    if (this.state.nextPage != null) {
      let res = await axiosInstance.get(
        `/tutorials/?page=${this.state.nextPage}`
      );
      let data = await res.data;
      this.setState({
        posts: data.results,
        count: data.count,
        current_page: data.current_page,
        nextPage: data.next,
        prevPage: data.previous,
      });
    }
  }

  async paginatePrevBlogs() {
    if (this.state.prevPage != null) {
      let res = await axiosInstance.get(
        `/tutorials/?page=${this.state.prevPage}`
      );
      let data = await res.data;
      this.setState({
        posts: data.results,
        count: data.count,
        current_page: data.current_page,
        nextPage: data.next,
        prevPage: data.previous,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="main-content">
          <div className="container-xl">
            <div className="row gy-4">
              <div className="col-lg-8">
                {/* <!-- post --> */}
                {this.state.posts.map((element) => {
                  return (
                    <div key={element.slug}>
                      <PostSection post={element} />
                    </div>
                  );
                })}

                {/* Pagination */}
                <nav>
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a
                        className="page-link"
                        role="button"
                        onClick={this.paginatePrevBlogs}
                      >
                        {this.state.prevPage ? this.state.prevPage : "#"}
                      </a>
                    </li>
                    <li className="page-item active" aria-current="page">
                      <span className="page-link">
                        {this.state.current_page}
                      </span>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        role="button"
                        onClick={this.paginateNextBlogs}
                      >
                        {this.state.nextPage ? this.state.nextPage : "#"}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              {/* Right Sidebar */}
              <div className="col-lg-4">
                <RightSidebar />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
