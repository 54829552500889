import React, { Component } from "react";
import CoursesSection from "./components/Courses_section";
import axios from "axios";
import $ from "jquery";

export default class Udemy extends Component {
  constructor() {
    super();
    this.state = {
      courses: [],
      count: 0,
      current_page: 0,
      nextPage: 0,
      prevPage: 0,
    };
    this.paginateNextCourses = this.paginateNextCourses.bind(this);
    this.paginatePrevCourses = this.paginatePrevCourses.bind(this);
  }

  async componentDidMount() {
    let res = await axios.get(
      `https://fretek.tekpython.com/tekpython_udemy_courses`,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "text/html,application/json",
        },
      }
    );
    let data = await res.data.data;
    this.setState({
      courses: data.results,
      count: data.count,
      current_page: data.current_page,
      nextPage: data.next_page,
      prevPage: data.prev_page,
    });

    var $headerClone = $(
        ".header-default.clone, .header-personal nav.clone, .header-classic.clone .header-bottom.clone"
      ),
      $cloneHeader = $headerClone.remove();

    var $header = $(
        ".header-default, .header-personal nav, .header-classic .header-bottom"
      ),
      $clone = $header.before($header.clone().addClass("clone"));

    $(window).on("scroll", function () {
      var fromTop = $(window).scrollTop();
      $("body").toggleClass("down", fromTop > 300);
    });
  }

  async paginateNextCourses() {
    if (this.state.nextPage != null) {
      let res = await axios.get(
        `https://fretek.tekpython.com/tekpython_udemy_courses/page/${this.state.nextPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "text/html,application/json",
          },
        }
      );
      let data = await res.data.data;
      this.setState({
        courses: data.results,
        count: data.count,
        current_page: data.current_page,
        nextPage: data.next_page,
        prevPage: data.prev_page,
      });
    }
  }

  async paginatePrevCourses() {
    if (this.state.prevPage != null) {
      let res = await axios.get(
        `https://fretek.tekpython.com/tekpython_udemy_courses/page/${this.state.prevPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            accept: "text/html,application/json",
          },
        }
      );
      let data = await res.data.data;
      this.setState({
        courses: data.results,
        count: data.count,
        current_page: data.current_page,
        nextPage: data.next_page,
        prevPage: data.prev_page,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="main-content">
          <div className="container-xl">
            <div className="row">
              {/* Udemy Courses */}
              {this.state.courses.map((element) => {
                return (
                  <CoursesSection
                    key={element.id}
                    course={element}
                    courses={this.state.courses}
                  />
                );
              })}
            </div>

            {/* Pagination */}
            <nav>
              <ul className="pagination justify-content-center">
                <li className="page-item">
                  <a
                    className="page-link"
                    role="button"
                    onClick={this.paginatePrevCourses}
                  >
                    {this.state.prevPage ? this.state.prevPage : "#"}
                  </a>
                </li>
                <li className="page-item active" aria-current="page">
                  <span className="page-link">{this.state.current_page}</span>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    role="button"
                    onClick={this.paginateNextCourses}
                  >
                    {this.state.nextPage ? this.state.nextPage : "#"}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
