import React, { Component } from "react";
import RightSidebar from "../how_to/Right_sidebar";
import axiosInstance, { baseURL } from "../api/axiosInstance";
import { useParams } from "react-router-dom";
import dateFormat from "dateformat";
import ReactHtmlParser from "react-html-parser";
import waveSvg from "../../assets/images/wave.svg";
import tpLogo from "../../assets/images/tekpython_logo.png";
import userLogo from "../../assets/images/other/user-logo.png";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    return <HTPostDetails {...props} params={params} />;
  }
  return ComponentWithRouter;
}

class HTPostDetails extends Component {
  constructor() {
    super();
    this.state = {
      post_slug: "",
      name: "",
      email: "",
      website: "",
      comment: "",
      post: {},
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    await this.setState({
      slug: this.props.params.slug,
    });
    let res = await axiosInstance.get(`/how-to/post/${this.state.slug}`);
    let data = await res.data;
    this.setState({
      post: data.post,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    try{
      let res = await axiosInstance.post(
        `/how-to/post/${this.state.slug}/comment`,
        {
          name: this.state.name,
          email: this.state.email,
          website: this.state.website,
          comment: this.state.comment,
        }
      );
      toast.success(res.data.message, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        theme: "colored",
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      this.setState({
        name: "",
        email: "",
        website: "",
        comment: "",
      });
      let res2 = await axiosInstance.get(`/how-to/post/${this.state.slug}`);
      let data = await res2.data;
      this.setState({
        post: data.post,
      });
    } catch (err) {
      if (err.response.data.message != undefined){
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    }
  }

  render() {
    let post = this.state.post;
    return (
      <React.Fragment>
        <section className="main-content mt-3">
          <div className="container-xl">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">{post.category?.name}</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {post.title}
                </li>
              </ol>
            </nav>

            <div className="row gy-4">
              <div className="col-lg-8">
                {/* <!-- post single --> */}
                <div className="post post-single">
                  {/* <!-- post header --> */}
                  <div className="post-header">
                    <h1 className="title mt-0 mb-3">{post.title}</h1>
                    <ul className="meta list-inline mb-0">
                      <li className="list-inline-item">
                        <a href="#">
                          {/* <img
                            src="images/other/author-sm.png"
                            className="author"
                            alt="author"
                          /> */}
                          {post.author?.username}
                        </a>
                      </li>
                      {/* <li className="list-inline-item">
                        <a href="#">Trending</a>
                      </li> */}
                      <li className="list-inline-item">
                        {dateFormat(post.created_on, "dddd, mmmm dS, yyyy")}
                      </li>
                    </ul>
                  </div>
                  {/* <!-- featured image --> */}
                  <div className="featured-image">
                    <img src={`${baseURL}${post.image}`} alt={post.title} className="w-100" />
                  </div>
                  {/* <!-- post content --> */}
                  <div className="post-content clearfix">
                    {ReactHtmlParser(post.content)}
                  </div>
                  {/* <!-- post bottom section --> */}
                  <div className="post-bottom">
                    <div className="row d-flex align-items-center">
                      <TagsSection post={post} />

                      <div className="col-md-6 col-12">
                        {/* <!-- social icons --> */}
                        <ul className="social-icons list-unstyled list-inline mb-0 float-md-end">
                          <li className="list-inline-item">
                            <a href="#">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="#">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="#">
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="#">
                              <i className="far fa-envelope"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="spacer" data-height="50"></div> */}

                {/* <div className="about-author padding-30 rounded">
                  <div className="thumb">
                    <img src="images/other/avatar-about.png" alt="Katen Doe" />
                  </div>
                  <div className="details">
                    <h4 className="name">
                      <a href="#">{post.author?.username}</a>
                    </h4>
                    <p>
                      Hello, I’m a content writer who is fascinated by content
                      fashion, celebrity and lifestyle. She helps clients bring
                      the right content to the right people.
                    </p>
                    <ul className="social-icons list-unstyled list-inline mb-0">
                      <li className="list-inline-item">
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}

                <div className="mt-5"></div>

                {/* <!-- section header --> */}
                <div className="section-header">
                  <h3 className="section-title">Comments ({post.comment?.length})</h3>
                  <img src={waveSvg} className="wave" alt="wave" />
                </div>
                {/* <!-- post comments --> */}
                <div className="comments bordered padding-30 rounded">
                  <ul className="comments">
                    {post.comment?.map((element) => {
                      return <PostCommentSection comment={element} key={element.id} />
                    })}
                  </ul>
                </div>

                <div className="mt-5"></div>

                {/* <!-- section header --> */}
                <div className="section-header">
                  <h3 className="section-title">Leave Comment</h3>
                  <img src={waveSvg} className="wave" alt="wave" />
                </div>
                {/* <!-- comment form --> */}
                <div className="comment-form rounded bordered padding-30">
                  <form
                    id="comment-form"
                    className="comment-form"
                    method="post"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="messages"></div>

                    <div className="row">
                      <div className="column col-md-12">
                        {/* <!-- Comment textarea --> */}
                        <div className="form-group">
                          <textarea
                            name="comment"
                            id="InputComment"
                            className="form-control"
                            rows="4"
                            placeholder="Your comment here..."
                            required="required"
                            value={this.state.comment}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="column col-md-6">
                        {/* <!-- Email input --> */}
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            id="InputEmail"
                            name="email"
                            placeholder="Email address"
                            required="required"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="column col-md-6">
                        {/* <!-- Name input --> */}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="website"
                            id="InputWeb"
                            placeholder="Website"
                            required="required"
                            value={this.state.website}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="column col-md-12">
                        {/* <!-- Email input --> */}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Your name"
                            required="required"
                            value={this.state.name}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      name="submit"
                      id="submit"
                      value="Submit"
                      className="btn btn-default"
                    >
                      Submit
                    </button>
                  </form>
                  <ToastContainer />
                </div>
              </div>

              {/* Right Sidebar */}
              <div className="col-lg-4">
                <RightSidebar />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export class TagsSection extends React.Component {
  render() {
    let { post } = this.props;
    return (
      <React.Fragment>
        <div className="col-md-6 col-12 text-center text-md-start">
          {/* <!-- tags --> */}
          {Object.keys(post).length > 0 &&
            post?.tags.map((tag, idx) => {
              return (
                <a href="#" className="tag" key={idx}>
                  #{tag.name}
                </a>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}

function ReplyComment(props) {
  const comment = props.comment;
  if (comment.reply_comment != null && comment.reply_comment != "") {
    return (
      <li className="comment child rounded mb-2">
        <div className="thumb">
          <img src={tpLogo} alt="TekPython" style={{ height: "38px" }} />
        </div>
        <div className="details">
          <h4 className="name">
            <a href="#">TekPython</a>
          </h4>
          <span className="date">
            {dateFormat(comment.replied_on, "mmmm dS, yyyy")}
          </span>
          <p>{comment.reply_comment}</p>
          {/* <a href="#" className="btn btn-default btn-sm">
          Reply
        </a> */}
        </div>
      </li>
    );
  }
  return <></>;
}

export class PostCommentSection extends React.Component {
  render() {
    let { comment } = this.props;
    return (
      <React.Fragment>
        {/* <!-- comment item --> */}
        <li className="comment rounded mb-2">
          <div className="thumb">
            <img
              src={userLogo}
              alt={comment?.name}
              style={{ height: "38px" }}
            />
          </div>
          <div className="details">
            <h4 className="name">
              <a href={comment?.website} rel="noreferrer">
                {comment?.name}
              </a>
            </h4>
            <span className="date">
              {dateFormat(comment?.created_on, "mmmm dS, yyyy")}
            </span>
            <p>{comment?.comment}</p>
            {/* <a href="#" className="btn btn-default btn-sm">
              Reply
            </a> */}
          </div>
        </li>

        {/* <!-- comment item --> */}
        <ReplyComment comment={comment} />
      </React.Fragment>
    );
  }
}

export class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post_slug: props.post_slug,
      name: "",
      email: "",
      website: "",
      comment: "",
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    let res = await axiosInstance.post(
      `/how-to/post/${this.props.post_slug}/comment`,
      {
        name: this.state.name,
        email: this.state.email,
        website: this.state.website,
        comment: this.state.comment,
      }
    );
    if (res.status == 200) {
      this.setState({
        name: "",
        email: "",
        website: "",
        comment: "",
      });
    }
    let data = await res.data;
    alert("Comment submitted: " + JSON.stringify(data));
  }

  render() {
    return (
      <form
        id="comment-form"
        className="comment-form"
        method="post"
        onSubmit={this.handleSubmit}
      >
        <div className="messages"></div>

        <div className="row">
          <div className="column col-md-12">
            {/* <!-- Comment textarea --> */}
            <div className="form-group">
              <textarea
                name="comment"
                id="InputComment"
                className="form-control"
                rows="4"
                placeholder="Your comment here..."
                required="required"
                value={this.state.comment}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="column col-md-6">
            {/* <!-- Email input --> */}
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="InputEmail"
                name="email"
                placeholder="Email address"
                required="required"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="column col-md-6">
            {/* <!-- Name input --> */}
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="website"
                id="InputWeb"
                placeholder="Website"
                required="required"
                value={this.state.website}
                onChange={this.handleInputChange}
              />
            </div>
          </div>

          <div className="column col-md-12">
            {/* <!-- Email input --> */}
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Your name"
                required="required"
                value={this.state.name}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </div>

        <button
          type="submit"
          name="submit"
          id="submit"
          value="Submit"
          className="btn btn-default"
        >
          Submit
        </button>
      </form>
    );
  }
}

const PostDetail = withRouter(HTPostDetails);

export default PostDetail;
