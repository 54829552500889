import React, {Component} from "react";
import RightSidebar from "../how_to/Right_sidebar";
import PostSection from "./components/Post_section";
import axiosInstance from "../api/axiosInstance";
import { useParams } from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouter(props) {
    let params = useParams();
    return <HTCategory {...props} params={params} />;
  }
  return ComponentWithRouter;
}

class HTCategory extends Component {
  constructor() {
    super();
    this.state = {
      howto: [],
      count: 0,
      current_page: 0,
      nextPage: "",
      prevPage: "",
      category_slug: "",
    };
    this.paginateNextBlogs = this.paginateNextBlogs.bind(this);
    this.paginatePrevBlogs = this.paginatePrevBlogs.bind(this);
  }

  async componentDidMount() {
    await this.setState({
      category_slug: this.props.params.slug,
    });
    let res = await axiosInstance.get(`/how-to/category/${this.state.category_slug}`);
    let data = await res.data;
    this.setState({
      howto: data.results,
      count: data.count,
      current_page: data.current_page,
      nextPage: data.next,
      prevPage: data.previous,
    });
  }

  async paginateNextBlogs() {
    if (this.state.nextPage != null) {
      let res = await axiosInstance.get(`/how-to/category/${this.state.category_slug}`);
      let data = await res.data;
      this.setState({
        howto: data.results,
        count: data.count,
        current_page: data.current_page,
        nextPage: data.next,
        prevPage: data.previous,
      });
    }
  }

  async paginatePrevBlogs() {
    if (this.state.prevPage != null) {
      let res = await axiosInstance.get(`/how-to/category/${this.state.category_slug}`);
      let data = await res.data;
      this.setState({
        howto: data.results,
        count: data.count,
        current_page: data.current_page,
        nextPage: data.next,
        prevPage: data.previous,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <section className="page-header">
          <div className="container-xl">
            <div className="text-center">
              <h1 className="mt-0 mb-2">Category</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center mb-0">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {this.state.category_slug}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </section>

        {/* <!-- section main content --> */}
        <section className="main-content">
          <div className="container-xl">
            <div className="row gy-4">
              <div className="col-lg-8">
                {this.state.howto.map((element) => {
                  return (
                    <div key={element.slug}>
                      <PostSection post={element} />
                    </div>
                  );
                })}

                {/* Pagination */}
                <nav>
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a
                        className="page-link"
                        role="button"
                        onClick={this.paginatePrevBlogs}
                      >
                        {this.state.prevPage ? this.state.prevPage : "#"}
                      </a>
                    </li>
                    <li className="page-item active" aria-current="page">
                      <span className="page-link">
                        {this.state.current_page}
                      </span>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        role="button"
                        onClick={this.paginateNextBlogs}
                      >
                        {this.state.nextPage ? this.state.nextPage : "#"}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              {/* Right Sidebar */}
              <div className="col-lg-4">
                <RightSidebar />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const CategoryPage = withRouter(HTCategory);

export default CategoryPage;