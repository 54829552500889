import axios from "axios";

// export const baseURL = "http://127.0.0.1:8000";
export const baseURL = "https://backend.tekpython.com";

const axiosInstance = axios.create({
  baseURL: baseURL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // config.headers["Access-Control-Allow-Origin"] = "*";
    // config.headers["Access-Control-Allow-Credentials"] = "true";
    config.headers["Content-Type"] = "application/json";
    config.headers["accept"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;
