import React, { Component } from "react";
import BlogsSection from "./components/Blogs_section";
import HowToSection from "./components/How_to_section";
import RightSidebar from "../blogs/Right_sidebar";
import TopSliderTabs from "./components/Top_slider_and_tabs";
import TutorialsSection from "./components/Tutorials_section";
import UdemyCoursesSection from "./components/Udemy_courses_section";
import axiosInstance from "../api/axiosInstance";
import $ from "jquery";

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      howto: [],
      tutorials: [],
      blogs: [],
      all_popular_posts: [],
      all_recent_posts: [],
    };
  }

  async componentDidMount() {
    let res = await axiosInstance.get(`/home`);
    let data = await res.data;
    this.setState({
      howto: data.howto,
      tutorials: data.tutorials,
      blogs: data.blogs,
      all_popular_posts: data.all_popular_posts,
      all_recent_posts: data.all_recent_posts,
    });

    var $headerClone = $(
        ".header-default.clone, .header-personal nav.clone, .header-classic.clone .header-bottom.clone"
      ),
      $cloneHeader = $headerClone.remove();

    var $header = $(
        ".header-default, .header-personal nav, .header-classic .header-bottom"
      ),
      $clone = $header.before($header.clone().addClass("clone"));

    $(window).on("scroll", function () {
      var fromTop = $(window).scrollTop();
      $("body").toggleClass("down", fromTop > 300);
    });
  }

  render() {
    return (
      <React.Fragment>
        <TopSliderTabs
          popular_posts={this.state.all_popular_posts}
          recent_posts={this.state.all_recent_posts}
        />

        <section className="main-content">
          <div className="container-xl">
            <div className="row gy-4">
              <div className="col-lg-8">
                <HowToSection posts={this.state.howto} />
                <div className="mb-4"></div>
                <TutorialsSection posts={this.state.tutorials} />
                <div className="mb-4"></div>
                <BlogsSection posts={this.state.blogs} />
                <div className="mb-4"></div>
                <UdemyCoursesSection />
                <div className="mb-4"></div>
              </div>

              <div className="col-lg-4">
                <RightSidebar />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
