import React from "react";
import tpBanner from "../../assets/images/tekpython_banner.png";
import { NavLink, Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <header className="header-default">
        <nav className="navbar navbar-expand-lg">
          <div className="container-xl">
            {/* <!-- site logo --> */}
            <Link to="/" className="navbar-brand">
              <img
                src={tpBanner}
                alt="TekPython Logo"
                style={{ height: "38px" }}
              />
            </Link>

            <div className="collapse navbar-collapse">
              {/* <!-- menus --> */}
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <NavLink to="/" activeclassname="active" className="nav-link">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/how-to"
                    activeclassname="active"
                    className="nav-link"
                  >
                    How To
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/tutorials"
                    activeclassname="active"
                    className="nav-link"
                  >
                    Tutorials
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/udemy"
                    activeclassname="active"
                    className="nav-link"
                  >
                    Udemy
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/blogs"
                    activeclassname="active"
                    className="nav-link"
                  >
                    Blogs
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#">
                    Others
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to="/about" className="dropdown-item">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" className="dropdown-item">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            {/* <!-- header right section --> */}
            <div className="header-right">
              {/* <!-- social icons --> */}
              <ul className="social-icons list-unstyled list-inline mb-0">
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/review2tech/">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
              {/* <!-- header buttons --> */}
              <div className="header-buttons">
                <button className="search icon-button">
                  <i className="icon-magnifier"></i>
                </button>
                <button className="burger-menu icon-button">
                  <span className="burger-icon"></span>
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
