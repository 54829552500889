import React from "react";
import tpBanner from "../../assets/images/tekpython_banner.png";
import tpBannerBack from "../../assets/images/map-bg.png";
import waveSvg from "../../assets/images/wave.svg";
import axiosInstance, { baseURL } from "../api/axiosInstance";
import dateFormat from "dateformat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export default class RightSidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      popular_tutorials: [],
      tutorial_category: [],
      subscribe_email: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    let res = await axiosInstance.get(`/tutorials/popular-tutorials`);
    let data = await res.data;
    this.setState({
      popular_tutorials: data.popular_tutorials,
    });
    let res2 = await axiosInstance.get(`/tutorials/category`);
    let data2 = await res2.data;
    this.setState({
      tutorial_category: data2.tutorial_category,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    try {
      let res = await axiosInstance.post(`/subscribe-email`, {
        email: this.state.subscribe_email,
      });
      toast.success(res.data.message, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        theme: "colored",
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      this.setState({
        subscribe_email: "",
      });
    } catch (err) {
      if (err.response.data.message != undefined) {
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      } else {
        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* <!-- sidebar --> */}
        <div className="sidebar">
          {/* <!-- widget about --> */}
          <div className="widget rounded">
            <div
              className="widget-about data-bg-image text-center"
              data-bg-image={tpBannerBack}
            >
              <img
                src={tpBanner}
                alt="FreTek Logo"
                style={{ height: "38px" }}
                className="mb-4"
              />
              <p className="mb-4">
                Hello, We're content writer who is fascinated by tech content, free courses and cool tips and tricks. We helps clients bring the
                right content to the right people.
              </p>
              <ul className="social-icons list-unstyled list-inline mb-0">
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/review2tech/">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* <!-- widget popular posts --> */}
          <div className="widget rounded">
            <div className="widget-header text-center">
              <h3 className="widget-title">Popular Blogs</h3>
              <img src={waveSvg} className="wave" alt="wave" />
            </div>
            <div className="widget-content">
              {/* <!-- post --> */}

              {this.state.popular_tutorials.map((blog, idx) => {
                return (
                  <div className="post post-list-sm circle" key={idx}>
                    <div className="thumb circle">
                      <span className="number">{idx + 1}</span>
                      <Link to={`/tutorial/${blog.slug}`}>
                        <div className="inner">
                          <img
                            src={`${baseURL}${blog.image}`}
                            alt={blog.title}
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="details clearfix">
                      <h6 className="post-title my-0">
                        <Link to={`/tutorial/${blog.slug}`}>{blog.title}</Link>
                      </h6>
                      <ul className="meta list-inline mt-1 mb-0">
                        <li className="list-inline-item">
                          {dateFormat(blog.created_on, "mmmm dS, yyyy")}
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <!-- widget categories --> */}
          <div className="widget rounded">
            <div className="widget-header text-center">
              <h3 className="widget-title">Explore Topics</h3>
              <img src={waveSvg} className="wave" alt="wave" />
            </div>
            <div className="widget-content">
              <ul className="list">
                {this.state.tutorial_category.map((category) => {
                  return (
                    <li key={category.id}>
                      <Link to={`/tutorials/category/${category.slug}`}>
                        {category.name}
                      </Link>
                      <span>({category.posts_count})</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>

          {/* <!-- widget newsletter --> */}
          <div className="widget rounded">
            <div className="widget-header text-center">
              <h3 className="widget-title">Newsletter</h3>
              <img src={waveSvg} className="wave" alt="wave" />
            </div>
            <div className="widget-content">
              <span className="newsletter-headline text-center mb-3">
                Join 1,000 subscribers!
              </span>
              <form method="post" onSubmit={this.handleSubmit}>
                <div className="mb-2">
                  <input
                    className="form-control w-100 text-center"
                    placeholder="Email address…"
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  />
                </div>
                <button className="btn btn-default btn-full" type="submit">
                  Sign Up
                </button>
              </form>
              <span className="newsletter-privacy text-center mt-3">
                By signing up, you agree to our{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </span>
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
