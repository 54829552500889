import React, { Component } from "react";
import waveSvg from "../../../assets/images/wave.svg";
import { baseURL } from "../../api/axiosInstance";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

export default class HowToSection extends Component {
  render() {
    let { posts } = this.props;
    let firstPost = posts[0];
    let remPost = posts.slice(-4);
    return (
      <React.Fragment>
        <div className="section-header">
          <h3 className="section-title">How To (Tips & Tricks)</h3>
          <img src={waveSvg} className="wave" alt="wave" />
        </div>

        <div className="padding-30 rounded bordered">
          <div className="row gy-5">
            <div className="col-sm-6">
              {/* <!-- post --> */}
              <div className="post">
                <div className="thumb rounded">
                  <a
                    href="#"
                    className="category-badge position-absolute"
                  >
                    {firstPost?.category.name}
                  </a>
                  <span className="post-format">
                    <i className="icon-picture"></i>
                  </span>
                  <Link to={`/how-to/${firstPost?.slug}`}>
                    <div className="inner">
                      <img
                        src={`${baseURL}${firstPost?.image}`}
                        alt={firstPost?.title}
                      />
                    </div>
                  </Link>
                </div>
                <ul className="meta list-inline mt-4 mb-0">
                  <li className="list-inline-item">
                    <a href="#">
                      {/* <img
                        src="images/other/author-sm.png"
                        className="author"
                        alt="author"
                      /> */}
                      {firstPost?.author.username}
                    </a>
                  </li>
                  <li className="list-inline-item">
                    {dateFormat(firstPost?.created_on, "mmmm dS, yyyy")}
                  </li>
                </ul>
                <h5 className="post-title mb-3 mt-3">
                  <Link to={`/how-to/${firstPost?.slug}`}>{firstPost?.title}</Link>
                </h5>
                <p className="excerpt mb-0">{firstPost?.metades}</p>
              </div>
            </div>

            <div className="col-sm-6">
              {/* <!-- post --> */}
              {remPost?.map((post) => {
                return (
                  <div className="post post-list-sm square" key={post.slug}>
                    <div className="thumb rounded">
                      <Link to={`/how-to/${post.slug}`}>
                        <div className="inner">
                          <img
                            src={`${baseURL}${post.image}`}
                            alt={post.title}
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="details clearfix">
                      <h6 className="post-title my-0">
                        <Link to={`/how-to/${post.slug}`}>{post.title}</Link>
                      </h6>
                      <ul className="meta list-inline mt-1 mb-0">
                        <li className="list-inline-item">
                          {dateFormat(post.created_on, "mmmm dS, yyyy")}
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
