import React from "react";
import RightSidebar from "../udemy/Right_sidebar";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import CountdownTimer from './components/countdown-timer';

export default function UPostDetails(props) {
  const location = useLocation();
  const course = location.state.course;
  const courses = location.state.courses.slice(0, 4);
  var endDate = new Date(course.end_time);
  var END_DATE_IN_MS = endDate.getTime();
  const dateTimeAfterThreeDays = END_DATE_IN_MS;

  return (
    <React.Fragment>
      <section className="main-content mt-3">
        <div className="container-xl">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">{course.category}</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {course.title}
              </li>
            </ol>
          </nav>

          <div className="row gy-4">
            <div className="col-lg-8">
              {/* <!-- post single --> */}
              <div className="post post-single">
                {/* <!-- post header --> */}
                <div className="post-header">
                  <h1 className="title mt-0 mb-3">[100% Off Coupon] {course.title}</h1>
                </div>
                <div className="post-content clearfix mb-4">
                  <CountdownTimer targetDate={dateTimeAfterThreeDays} />
                </div>
                {/* <!-- featured image --> */}
                <div className="featured-image">
                  <img
                    src={course.image}
                    alt={course.title}
                    className="w-100"
                  />
                </div>
                {/* <!-- post content --> */}
                <div className="fs-3 clearfix mt-5">
                  <sb>Price:</sb> <s>{course.price}</s> <b>Free</b>
                </div>
                <div className="post-content clearfix mt-4">
                  <ul className="list-inline mb-0">
                    <li className="list-item">
                      Created by: {course.author}
                    </li>
                    <li className="list-item">
                      Enroll: {course.students} students
                    </li>
                    <li className="list-item">
                      Rating: {course.rating} <i className="icon-star"></i> ({course.rating_count})
                    </li>
                  </ul>
                </div>
                <div className="post-content clearfix mt-4">
                  {course.subtitle}
                </div>
                <div className="post-content clearfix mt-3">
                  All udemy courses shown in this app is 100% free. If it is
                  shown here and not free when you go to through out link. This
                  is mainly because the owner might have removed the coupon or
                  the coupon might have expired. Coupon are only valid for
                  maximum of 4 days but if you enroll it you can watch anytime,
                  anywhere. So Enroll Now.
                </div>
                <div className="post-content clearfix mt-3">
                  ====== Update ======
                </div>
                <div className="post-content clearfix">
                  Courses are updated hourly it means that you can find new
                  courses every hour so stay tuned and check our app regularly
                  for your desired course.
                </div>
                <div className="post-content clearfix mt-5">
                  <a href={course.url} target="_blank" className="btn btn-default btn-full">
                    Enroll Now
                  </a>
                </div>
                {/* <!-- post bottom section --> */}
                <div className="post-bottom">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      {/* <!-- social icons --> */}
                      <ul className="social-icons list-unstyled list-inline mb-0">
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="#">
                            <i className="far fa-envelope"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Sidebar */}
            <div className="col-lg-4">
              <RightSidebar courses={courses} />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
