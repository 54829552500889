import React from "react";
import { useCountdown } from "./hooks/useCountdown";

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return (
      <div className="expired-notice">
        <span>Expired!!!</span>
      </div>
    );
  } else {
    return (
      <div className="show-counter">
        <div className="countdown-link">
          <span className="offer-end-text">Offer End In:</span>
          <div className={days <= 4 ? "countdown danger" : "countdown"}>
            <p>{days}</p>
            <span>{"Days"}</span>
          </div>
          <p>:</p>
          <div className={false ? "countdown danger" : "countdown"}>
            <p>{hours}</p>
            <span>{"Hours"}</span>
          </div>
          <p>:</p>
          <div className={false ? "countdown danger" : "countdown"}>
            <p>{minutes}</p>
            <span>{"Mins"}</span>
          </div>
          <p>:</p>
          <div className={false ? "countdown danger" : "countdown"}>
            <p>{seconds}</p>
            <span>{"Seconds"}</span>
          </div>
        </div>
      </div>
    );
  }
};

export default CountdownTimer;
