import React, { Component } from "react";
import waveSvg from "../../../assets/images/wave.svg";
import { baseURL } from "../../api/axiosInstance";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

export default class TutorialsSection extends Component {
  render() {
    let { posts } = this.props;
    let startThree = posts.slice(0, 3);
    let firstStartThree = startThree[0];
    let remStartThree = startThree.slice(-2);
    let endThree = posts.slice(-3);
    let firstEndThree = endThree[0];
    let remEndThree = endThree.slice(-2);
    return (
      <React.Fragment>
        <div className="section-header">
          <h3 className="section-title">Tutorials</h3>
          <img src={waveSvg} className="wave" alt="wave" />
        </div>

        <div className="padding-30 rounded bordered">
          <div className="row gy-5">
            <div className="col-sm-6">
              {/* <!-- post large --> */}
              <div className="post">
                <div className="thumb rounded">
                  <a href="#" className="category-badge position-absolute">
                    {firstStartThree?.category.name}
                  </a>
                  <span className="post-format">
                    <i className="icon-picture"></i>
                  </span>
                  <Link to={`/tutorial/${firstStartThree?.slug}`}>
                    <div className="inner">
                      <img
                        src={`${baseURL}${firstStartThree?.image}`}
                        alt={firstStartThree?.title}
                      />
                    </div>
                  </Link>
                </div>
                <ul className="meta list-inline mt-4 mb-0">
                  <li className="list-inline-item">
                    <a href="#">
                      {/* <img
                      src="images/other/author-sm.png"
                      className="author"
                      alt="author"
                    /> */}
                      {firstStartThree?.author.username}
                    </a>
                  </li>
                  <li className="list-inline-item">
                    {dateFormat(firstStartThree?.created_on, "mmmm dS, yyyy")}
                  </li>
                </ul>
                <h5 className="post-title mb-3 mt-3">
                  <Link to={`/tutorial/${firstStartThree?.slug}`}>
                    {firstStartThree?.title}
                  </Link>
                </h5>
                <p className="excerpt mb-0">{firstStartThree?.metades}</p>
              </div>
              {/* <!-- post --> */}
              {remStartThree?.map((post) => {
                return (
                  <div className="post post-list-sm square before-seperator" key={post.slug}>
                    <div className="thumb rounded">
                      <Link to={`/tutorial/${post.slug}`}>
                        <div className="inner">
                          <img
                            src={`${baseURL}${post.image}`}
                            alt={post.title}
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="details clearfix">
                      <h6 className="post-title my-0">
                        <Link to={`/tutorial/${post.slug}`}>
                          {post.title}
                        </Link>
                      </h6>
                      <ul className="meta list-inline mt-1 mb-0">
                        <li className="list-inline-item">
                          {dateFormat(post.created_on, "mmmm dS, yyyy")}
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-sm-6">
              {/* <!-- post large --> */}
              <div className="post">
                <div className="thumb rounded">
                  <a href="#" className="category-badge position-absolute">
                    {firstEndThree?.category.name}
                  </a>
                  <span className="post-format">
                    <i className="icon-picture"></i>
                  </span>
                  <Link to={`/tutorial/${firstEndThree?.slug}`}>
                    <div className="inner">
                      <img
                        src={`${baseURL}${firstEndThree?.image}`}
                        alt={firstEndThree?.title}
                      />
                    </div>
                  </Link>
                </div>
                <ul className="meta list-inline mt-4 mb-0">
                  <li className="list-inline-item">
                    <a href="#">
                      {/* <img
                      src="images/other/author-sm.png"
                      className="author"
                      alt="author"
                    /> */}
                      {firstEndThree?.author.username}
                    </a>
                  </li>
                  <li className="list-inline-item">
                    {dateFormat(firstEndThree?.created_on, "mmmm dS, yyyy")}
                  </li>
                </ul>
                <h5 className="post-title mb-3 mt-3">
                  <Link to={`/tutorial/${firstEndThree?.slug}`}>
                    {firstEndThree?.title}
                  </Link>
                </h5>
                <p className="excerpt mb-0">{firstEndThree?.metades}</p>
              </div>
              {/* <!-- post --> */}
              {remEndThree?.map((post) => {
                return (
                  <div className="post post-list-sm square before-seperator" key={post.slug}>
                    <div className="thumb rounded">
                      <Link to={`/tutorial/${post.slug}`}>
                        <div className="inner">
                          <img
                            src={`${baseURL}${post.image}`}
                            alt={post?.title}
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="details clearfix">
                      <h6 className="post-title my-0">
                        <Link to={`/tutorial/${post.slug}`}>
                          {post.title}
                        </Link>
                      </h6>
                      <ul className="meta list-inline mt-1 mb-0">
                        <li className="list-inline-item">
                          {dateFormat(post.created_on, "mmmm dS, yyyy")}
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
