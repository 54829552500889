import React from "react";
import fretekAboutUs from "../../assets/images/tekpython-fretek-home-banner.jpg";

export default function AboutUs() {
  return (
    <React.Fragment>
      <section className="page-header">
        <div className="container-xl">
          <div className="text-center">
            <h1 className="mt-0 mb-2">About</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center mb-0">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      {/* <!-- section main content --> */}
      <section className="main-content">
        <div className="container-xl">
          <div className="row gy-4">
            <div className="col-lg-12">
              <div className="page-content bordered rounded padding-30">
                <img
                  src={fretekAboutUs}
                  alt="Katen Doe"
                  className="rounded mb-4"
                />

                <p>
                  Hello, I’m Subash Khatiwada. A content writer who is
                  fascinated by tech and who loves tech products. My childhood
                  love of hacking the neighbours wifi grew into an adulthood
                  love of programming and web page designing. Now, I am getting a great
                  oppurtunities to sharp my skills and live with with my Dream with TekPython. 
                  This Website is developed to provide:-
                </p>

                <ul>
                  <li>Tech Gadgets Review</li>
                  <li>Latest Tech News</li>
                  <li>Tech Blog</li>
                  <li>How To Tips and Tricks</li>
                  <li>Tutorials</li>
                  <li>Udemy Courses and more</li>
                </ul>

                <p>
                  This website is developed to review and give the detail
                  specifications of tech and tech products to update your
                  technical knowledge and skills by reading latest tech news and
                  tutorials regularly.
                </p>

                <hr className="my-4" />
                <ul className="social-icons list-unstyled list-inline mb-0">
                  <li className="list-inline-item">
                    <a href="https://www.facebook.com/review2tech/">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="col-lg-4">
              <RightSidebar />
            </div> */}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
