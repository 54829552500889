import React, { Component } from "react";
import waveSvg from "../../../assets/images/wave.svg";
import { baseURL } from "../../api/axiosInstance";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

export default class BlogsSection extends Component {
  render() {
    let { posts } = this.props;
    return (
      <React.Fragment>
        <div className="section-header">
          <h3 className="section-title">Blogs</h3>
          <img src={waveSvg} className="wave" alt="wave" />
          {/* <div className="slick-arrows-top">
            <button
              type="button"
              data-role="none"
              className="carousel-topNav-prev slick-custom-buttons"
              aria-label="Previous"
            >
              <i className="icon-arrow-left"></i>
            </button>
            <button
              type="button"
              data-role="none"
              className="carousel-topNav-next slick-custom-buttons"
              aria-label="Next"
            >
              <i className="icon-arrow-right"></i>
            </button>
          </div> */}
        </div>

        <div className="row">
          {/* <!-- post --> */}
          {posts?.map((post) => {
            return (
              <div className="post post-over-content col-md-6 mt-1 mb-1" key={post.slug}>
                <div className="details clearfix">
                  <a href="#" className="category-badge">
                    {post.category.name}
                  </a>
                  <h4 className="post-title">
                    <Link to={`/blog/${post.slug}`}>{post.title}</Link>
                  </h4>
                  <ul className="meta list-inline mb-0">
                    <li className="list-inline-item">
                      <a href="#">{post.author.username}</a>
                    </li>
                    <li className="list-inline-item">
                      {dateFormat(post.created_on, "mmmm dS, yyyy")}
                    </li>
                  </ul>
                </div>
                <Link to={`/blog/${post.slug}`}>
                  <div className="thumb rounded">
                    <div className="inner">
                      <img src={`${baseURL}${post.image}`} alt={post.title} />
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}
