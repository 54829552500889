import React, { Component } from "react";
import fretekHomeBanner from "../../../assets/images/tekpython-fretek-home-banner.jpg";
import { baseURL } from "../../api/axiosInstance";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

export default class TopSliderTabs extends Component {
  componentDidMount() {
    var list = document.getElementsByClassName("data-bg-image");

    for (var i = 0; i < list.length; i++) {
      var bgimage = list[i].getAttribute("data-bg-image");
      list[i].style.backgroundImage = "url('" + bgimage + "')";
    }
  }

  render() {
    let { popular_posts, recent_posts } = this.props;
    return (
      <section id="hero">
        <div className="container-xl">
          <div className="row gy-4">
            <div className="col-lg-8">
              {/* <!-- featured post large --> */}
              <div className="post featured-post-lg">
                <div className="details clearfix">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.subash.fretek"
                    target="_blank"
                    rel="noreferrer"
                    className="category-badge"
                  >
                    Application
                  </a>
                  <h2 className="post-title">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.subash.fretek"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FreTek - Free Online Courses
                    </a>
                  </h2>
                  <ul className="meta list-inline mb-0">
                    <li className="list-inline-item">
                      <a href="#">TekPython</a>
                    </li>
                    <li className="list-inline-item">12 March 2022</li>
                  </ul>
                </div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.subash.fretek"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="thumb rounded">
                    <div
                      className="inner data-bg-image"
                      data-bg-image={fretekHomeBanner}
                    ></div>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-lg-4">
              {/* <!-- post tabs --> */}
              <div className="post-tabs rounded bordered">
                {/* <!-- tab navs --> */}
                <ul
                  className="nav nav-tabs nav-pills nav-fill"
                  id="postsTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      aria-controls="popular"
                      aria-selected="true"
                      className="nav-link active"
                      data-bs-target="#popular"
                      data-bs-toggle="tab"
                      id="popular-tab"
                      role="tab"
                      type="button"
                    >
                      Popular
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      aria-controls="recent"
                      aria-selected="false"
                      className="nav-link"
                      data-bs-target="#recent"
                      data-bs-toggle="tab"
                      id="recent-tab"
                      role="tab"
                      type="button"
                    >
                      Recent
                    </button>
                  </li>
                </ul>
                {/* <!-- tab contents --> */}
                <div className="tab-content" id="postsTabContent">
                  {/* <!-- loader --> */}
                  <div className="lds-dual-ring"></div>
                  {/* <!-- popular posts --> */}
                  <div
                    aria-labelledby="popular-tab"
                    className="tab-pane fade show active"
                    id="popular"
                    role="tabpanel"
                  >
                    {/* <!-- post --> */}
                    {popular_posts?.map((postData, idx) => {
                      let post = postData.fields;
                      let model = postData.model;
                      let url = "";
                      if (model === "blog.blog") {
                        url = "/blog/";
                      } else if (model === "tutorials.tutorial") {
                        url = "/tutorial/";
                      } else {
                        url = "how-to/";
                      }
                      return (
                        <div className="post post-list-sm circle" key={idx}>
                          <div className="thumb circle">
                            <Link to={`${url}${post?.slug}`}>
                              <div className="inner">
                                <img
                                  src={`${baseURL}/media/${post?.image}`}
                                  alt={post?.title}
                                />
                              </div>
                            </Link>
                          </div>
                          <div className="details clearfix">
                            <h6 className="post-title my-0">
                              <Link to={`${url}${post?.slug}`}>
                                {post?.title}
                              </Link>
                            </h6>
                            <ul className="meta list-inline mt-1 mb-0">
                              <li className="list-inline-item">
                                {dateFormat(post?.created_on, "mmmm dS, yyyy")}
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* <!-- recent posts --> */}
                  <div
                    aria-labelledby="recent-tab"
                    className="tab-pane fade"
                    id="recent"
                    role="tabpanel"
                  >
                    {/* <!-- post --> */}
                    {recent_posts?.map((postData, idx) => {
                      let post = postData.fields;
                      let url = "postData.model";
                      return (
                        <div className="post post-list-sm circle" key={idx}>
                          <div className="thumb circle">
                            <Link to={`/blog/${post?.slug}`}>
                              <div className="inner">
                                <img
                                  src={`${baseURL}/media/${post?.image}`}
                                  alt={post?.title}
                                />
                              </div>
                            </Link>
                          </div>
                          <div className="details clearfix">
                            <h6 className="post-title my-0">
                              <Link to={`${url}${post?.slug}`}>
                                {post?.title}
                              </Link>
                            </h6>
                            <ul className="meta list-inline mt-1 mb-0">
                              <li className="list-inline-item">
                                {dateFormat(post?.created_on, "mmmm dS, yyyy")}
                              </li>
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
