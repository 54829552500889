import React from "react";
import { Link } from "react-router-dom";

export default class CoursesSection extends React.Component {
  render() {
    // Configuration for number format
    const COUNT_FORMATS = [
      {
        // 0 - 999
        letter: "",
        limit: 1e3,
      },
      {
        // 1,000 - 999,999
        letter: "K",
        limit: 1e6,
      },
      {
        // 1,000,000 - 999,999,999
        letter: "M",
        limit: 1e9,
      },
      {
        // 1,000,000,000 - 999,999,999,999
        letter: "B",
        limit: 1e12,
      },
    ];

    // Format Method:
    function formatCount(value) {
      const format = COUNT_FORMATS.find((format) => value < format.limit);

      value = (1000 * value) / format.limit;
      value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

      return value + format.letter;
    }

    function getSlugFromUrl(url, title) {
      try {
        let spliturl = url.split("udemy.com/course/");
        let finalurl = spliturl[1].split("/?Download");
        if (finalurl[0] != "") {
          return finalurl[0];
        } else {
          return title
            .toLowerCase()
            .replace(/[^\w ]+/g, "")
            .replace(/ +/g, "-");
        }
      } catch (error) {
        return title
          .toLowerCase()
          .replace(/[^\w ]+/g, "")
          .replace(/ +/g, "-");
      }
    }

    let { course, courses } = this.props;
    return (
      <React.Fragment>
        <div className="col-lg-6">
          {/* <!-- post section --> */}
          <div className="post post-classic rounded bordered">
            <div className="thumb top-rounded">
              <a href="#" className="category-badge lg position-absolute">
                {course.category}
              </a>
              <span className="post-format">
                <i className="icon-picture"></i>
              </span>
                <Link
                  to={`/udemy/${getSlugFromUrl(course.url, course.title)}`}
                  state={{
                    "course": course,
                    "courses": courses
                  }}
                >
                <div className="inner">
                  <img
                    src={course.image}
                    alt={course.title}
                    className="w-100"
                  />
                </div>
              </Link>
            </div>
            <div className="details">
              <ul className="meta list-inline mb-0">
                <li className="list-inline-item">
                  <a href="#">{course.author}</a>
                </li>
                <li className="list-inline-item">
                  <i className="icon-user"></i> {formatCount(course.students)}{" "}
                  students
                </li>
                <li className="list-inline-item">
                  <i className="icon-star"></i> {course.rating} (
                  {course.rating_count})
                </li>
              </ul>
              <h5 className="post-title mb-3 mt-3">
                <Link
                  to={`/udemy/${getSlugFromUrl(course.url, course.title)}`}
                  state={{
                    "course": course,
                    "courses": courses
                  }}
                >
                  [100% Off Coupon] {course.title}
                </Link>
              </h5>
              <p className="excerpt mb-0">{course.subtitle}</p>
            </div>
            <div className="post-bottom clearfix d-flex align-items-center">
              <div className="social-share me-auto">
                <button className="toggle-button icon-share"></button>
                <ul className="icons list-unstyled list-inline mb-0">
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="#">
                      <i className="far fa-envelope"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="float-end d-none d-md-block">
                <Link
                  to={`/udemy/${getSlugFromUrl(course.url, course.title)}`}
                  state={{
                    "course": course,
                    "courses": courses
                  }}
                  className="more-link"
                >
                  Enroll Now<i className="icon-arrow-right"></i>
                </Link>
              </div>
              <div className="more-button d-block d-md-none float-end">
                <Link
                  to={`/udemy/${getSlugFromUrl(course.url, course.title)}`}
                  state={{
                    "course": course,
                    "courses": courses
                  }}
                >
                  <span className="icon-options"></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
