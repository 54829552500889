import React from "react";
import tpBanner from "../../assets/images/tekpython_banner.png";
import { NavLink, Link } from "react-router-dom";

export default function PopupMenu() {
  return (
    <React.Fragment>
      {/* <!-- search popup area --> */}
      <div className="search-popup">
        <button type="button" className="btn-close" aria-label="Close"></button>
        <div className="search-content">
          <div className="text-center">
            <h3 className="mb-4 mt-0">Press ESC to close</h3>
          </div>
          <form className="d-flex search-form">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search and press enter ..."
              aria-label="Search"
            />
            <button className="btn btn-default btn-lg" type="submit">
              <i className="icon-magnifier"></i>
            </button>
          </form>
        </div>
      </div>

      {/* <!-- canvas menu --> */}
      <div className="canvas-menu d-flex align-items-end flex-column">
        <button type="button" className="btn-close" aria-label="Close"></button>

        <div className="logo">
          <img src={tpBanner} alt="logo" style={{ height: "38px" }} />
        </div>

        <nav>
          <ul className="vertical-menu">
            <li>
              <NavLink to="/" activeclassname="active">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/how-to" activeclassname="active">
                How To
              </NavLink>
            </li>
            <li>
              <NavLink to="/tutorials" activeclassname="active">
                Tutorials
              </NavLink>
            </li>
            <li>
              <NavLink to="/udemy" activeclassname="active">
                Udemy
              </NavLink>
            </li>
            <li>
              <NavLink to="/blogs" activeclassname="active">
                Blogs
              </NavLink>
            </li>
            <li>
              <a href="#">Others</a>
              <ul className="submenu">
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>

        <ul className="social-icons list-unstyled list-inline mb-0 mt-auto w-100">
          <li className="list-inline-item">
            <a href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a href="#">
              <i className="fab fa-twitter"></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a href="#">
              <i className="fab fa-instagram"></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a href="#">
              <i className="fab fa-youtube"></i>
            </a>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
