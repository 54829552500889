import React, { Component } from "react";
import waveSvg from "../../../assets/images/wave.svg";
import axios from "axios";
import { Link } from "react-router-dom";

export default class UdemyCoursesSection extends Component {
  constructor() {
    super();
    this.state = {
      courses: [],
    };
  }

  async componentDidMount() {
    let res = await axios.get(
      `https://fretek.tekpython.com/tekpython_udemy_courses`,
      {
        headers: {
          "Content-Type": "application/json",
          accept: "text/html,application/json",
        },
      }
    );
    let data = await res.data.data;
    this.setState({
      courses: data.results.slice(0, 4),
    });
  }

  render() {
    // Configuration for number format
    const COUNT_FORMATS = [
      {
        // 0 - 999
        letter: "",
        limit: 1e3,
      },
      {
        // 1,000 - 999,999
        letter: "K",
        limit: 1e6,
      },
      {
        // 1,000,000 - 999,999,999
        letter: "M",
        limit: 1e9,
      },
      {
        // 1,000,000,000 - 999,999,999,999
        letter: "B",
        limit: 1e12,
      },
    ];

    // Format Method:
    function formatCount(value) {
      const format = COUNT_FORMATS.find((format) => value < format.limit);

      value = (1000 * value) / format.limit;
      value = Math.round(value * 10) / 10; // keep one decimal number, only if needed

      return value + format.letter;
    }

    function getSlugFromUrl(url, title) {
      try {
        let spliturl = url.split("udemy.com/course/");
        let finalurl = spliturl[1].split("/?Download");
        if (finalurl[0] !== "") {
          return finalurl[0];
        } else {
          return title
            .toLowerCase()
            .replace(/[^\w ]+/g, "")
            .replace(/ +/g, "-");
        }
      } catch (error) {
        return title
          .toLowerCase()
          .replace(/[^\w ]+/g, "")
          .replace(/ +/g, "-");
      }
    }
    return (
      <React.Fragment>
        <div className="section-header">
          <h3 className="section-title">Udemy Courses</h3>
          <img src={waveSvg} className="wave" alt="wave" />
        </div>

        <div className="padding-30 rounded bordered">
          <div className="row">
            {/* <!-- post --> */}
            {this.state.courses.map((course) => {
              return (
                <div className="col-md-12 col-sm-6" key={course.id}>
                  <div className="post post-list clearfix">
                    <div className="thumb rounded">
                      <span className="post-format-sm">
                        <i className="icon-picture"></i>
                      </span>
                      <Link to={`/udemy/${getSlugFromUrl(course.url, course.title)}`}>
                        <div className="inner">
                          <img
                            src={course.image}
                            alt={course.title}
                            className="w-100"
                          />
                        </div>
                      </Link>
                    </div>
                    <div className="details">
                      <ul className="meta list-inline mb-3">
                        <li className="list-inline-item">
                          <a href="#">{course.author}</a>
                        </li>
                        <li className="list-inline-item">
                          <i className="icon-user"></i>{" "}
                          {formatCount(course.students)} students
                        </li>
                        <li className="list-inline-item">
                          <i className="icon-star"></i> {course.rating} (
                          {course.rating_count})
                        </li>
                      </ul>
                      <h5 className="post-title">
                        <a href="blog-single.html">{course.title}</a>
                      </h5>
                      <p className="excerpt mb-0">{course.subtitle}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <!-- load more button --> */}
          <div className="text-center">
            <Link to="/udemy" className="btn btn-simple">
              Load More
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
