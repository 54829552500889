import React from "react";
import Header from "./components/base/Header";
import Footer from "./components/base/Footer";
// import Preloader from "./components/base/Preloader";
import PopupMenu from "./components/base/PopupMenu";
import { Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import HowTo from "./components/how_to/How_to";
import Tutorials from "./components/tutorials/Tutorials";
import Udemy from "./components/udemy/Udemy";
import Blogs from "./components/blogs/Blogs";
import About from "./components/others/About_us";
import Contact from "./components/others/Contact_us";
import PrivacyPolicy from "./components/others/Privacy_policy";
import PostDetails from "./components/blogs/Post_details";
import HTPostDetails from "./components/how_to/Post_details";
import TPostDetails from "./components/tutorials/Post_details";
import UPostDetails from "./components/udemy/Post_details";
import NotFound404 from "./components/others/404_page";
import Category from "./components/blogs/Category";
import HTCategory from "./components/how_to/Category";
import TCategory from "./components/tutorials/Category";

function App() {
  return (
    <>
      {/* <Preloader /> */}

      {/* <!-- site wrapper --> */}
      <div className="site-wrapper">
        <div className="main-overlay"></div>

        <Header />

        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/how-to" element={<HowTo />} />
            <Route path="/tutorials" element={<Tutorials />} />
            <Route path="/udemy" element={<Udemy />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/blogs/category/:slug" element={<Category />} />
            <Route path="/how-to/category/:slug" element={<HTCategory />} />
            <Route path="/tutorials/category/:slug" element={<TCategory />} />
            <Route path="/blog/:slug" element={<PostDetails />} />
            <Route path="/how-to/:slug" element={<HTPostDetails />} />
            <Route path="/tutorial/:slug" element={<TPostDetails />} />
            <Route path="/udemy/:slug" element={<UPostDetails />} />
            <Route path="/*" element={<NotFound404 />} />
          </Routes>
        </div>

        <Footer />
      </div>

      <PopupMenu />
    </>
  );
}

export default App;
